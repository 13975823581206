import * as React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { container } from "./CheckoutNavigation.module.scss";

export const StyledListGroup = (props: any) => {
    return (
    <ListGroup 
        className={`${container} ${props.className}`}
        activeKey={props.activeKey}
        defaultActiveKey={props.defaultActiveKey}
        as={props.as}
    >
        {props.children}
    </ListGroup>);
};

export const StyledListItem = (props: {index: number, room: any, handleSelectRoom(event: any): void, currentRoomKey: number, filledClass: string, roomNumber: number}) => {
    return (<ListGroup.Item
                key={props.index}
                action
                eventKey={props.room.id}
                onSelect={props.handleSelectRoom}
                as="li"
                variant="secondary"
                active={props.currentRoomKey === props.room.id}
                className={`${props.filledClass}`}>
                <div className="d-flex align-items-center justify-content-center h-100">
                <div>
                    <span className="d-none d-lg-inline">Room</span>&nbsp;{props.roomNumber}
                </div>
                </div>
            </ListGroup.Item>);
};


