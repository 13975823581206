import React from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Container } from "./UnlockBannerHelpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { unlockPricing } from "../../../redux/slices/Rate/rate";
import { useUnlockDiscount } from "../../../hooks/useUnlockDiscount";

const UnlockBanner = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(state => state.member.isLoggedIn);
  const [showUnlockButton, discountUnlocked, showSpecialPricing, setShowSpecialPricing] = useUnlockDiscount('');

  const unlockedHeadline = "Welcome to Travel Pass";
  const unlockedByline = "You Have Unlocked Member Only Rates with Savings of 5% or More";

  const lockedHeadline = "Unlock & Save";
  const lockedByline =  "Join Travel Pass to Unlock Member Only Rates";
  // const unlockedPricing = useSelector(state => state.show_special_pricing);
  const showLocked = !discountUnlocked;// !isLoggedIn && !unlockedPricing;
  const headline = showLocked ? (isLoggedIn ? unlockedHeadline : lockedHeadline) : unlockedHeadline;
  const byline = showLocked ? (isLoggedIn ? unlockedByline : lockedByline) : unlockedByline;

  const handleUnlockPricing = () => {
    dispatch(unlockPricing(true));
  };
  return (
    <Container>
      <Col xs={12} lg className="headline">
        <FontAwesomeIcon icon="tag" className="me-3" flip="horizontal"/>
        {headline}
      </Col>
      <Col className="byline" xs={12} lg>
        {byline}
        {!showLocked &&
        <FontAwesomeIcon icon="check" className="ms-4"/>
        }
      </Col>
      {showLocked && (!isLoggedIn ?
      <Col xs={12} lg className="button">
        <Button
          variant="dark"
          size="lg"
          onClick={handleUnlockPricing}
          className="ps-5 pe-5 mt-3 mt-lg-0"
        >
          JOIN NOW
        </Button>
      </Col>
      : '')
      }
    </Container>
  );
};

export default UnlockBanner;
