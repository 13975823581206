import * as React from "react";
import { container, darkBackground } from "./CheckoutFinish.module.scss";

export const Container = (props: any) => {
  return <div className={container}>{props.children}</div>;
};
export const DarkBackground = (props: any) => {
  return (
    <div
      className={darkBackground}
      style={{ display: props.show ? "block" : "none" }}
    >
      {props.children}
    </div>
  );
};

export const ErrorMessage = (errorMessage: string) => {
  if (/Promotional\/Corporate Code/i.test(errorMessage)) {
    return "Your reservation was not confirmed. Check promotion or group code and try again.";
  } else if (/Rate Plan Code is required/i.test(errorMessage)) {
    return "Your reservation was not confirmed. Rate not available. Please select a different rate and try again.";
  } else if (/Room Type Code is required/i.test(errorMessage)) {
    return "Your reservation was not confirmed. Room not available. Please select a different room and try again.";
  } else if (/Arrival Date is after the Departure date/i.test(errorMessage)) {
    return "Your reservation was not confirmed. Try changing the date selection and resubmit.";
  } else if (/CVV Value not present/i.test(errorMessage)) {
    return "Your reservation was not confirmed. Check payment card CVV and try again.";
  } else if (/INVALID_CREDIT_CARD_DATE/i.test(errorMessage)) {
    return "Your reservation was not confirmed. Check payment card expiration date and try again.";
  } else if (
    /INVALID_PAYMENT_DETAILS|PROCESSOR_ERROR|CardValidation/i.test(errorMessage)
  ) {
    return "Your reservation was not confirmed. Check payment card information and try again.";
  } else if (/Hotel does not support this card type/i.test(errorMessage)) {
    return "Your reservation was not confirmed. Card type not accepted. Use a different payment card type.";
  } else {
    return "Your reservation was not confirmed. Please try again or try again later.";
  }
};
