import * as React from "react";
import { Row } from "react-bootstrap";
import { container, styledImage } from "./RoomHeader.module.scss";

export const Container = (props: any) => {
    return (<Row className={`${container} mb-2`}>{props.children}</Row>);
};
export const StyledImage = (props: any) => {
    return(<img src={props.src} alt={props.alt} id={props.id} className={styledImage}></img>);
};



