import React from "react";
import { IAccessibleFilterProps } from "./AccessibleFilterProps";
import { Container, ToggleSwitch } from "./AccessibleFilterHelpers";
import useMobileDetect from "../../../../hooks/useMobileDetect";

const AccessibleFilter = (props: IAccessibleFilterProps) => {
  const changeToggle = () => props.setAccessibleFilter(!props.accessibleFilter);
  const isMobileOnly = useMobileDetect();
  return (
    <Container><>
      <span className="prefLabel">Accessible Rooms</span>
      <ToggleSwitch>
        <>
        <input
          type="checkbox"
          id="accessibleRooms"
          checked={props.accessibleFilter}
          onChange={changeToggle}
          aria-label="Filter Accessible Rooms"    
        />
        <span className="slider round" />
        </>
      </ToggleSwitch>
      </></Container>
  );
};

export default AccessibleFilter;
