import { Link } from "gatsby";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Constants } from "../../../@types/Constants";
import ErrorMessageAlert from "../ErrorMessageAlert/ErrorMessageAlert";
import {
  container,
  subTitle,
  title,
  hotelName,
  guestInfo,
} from "./CheckoutGuestDetails.module.scss";

export const StyledRow = (props: any) => {
  return <Row className={container}>{props.children}</Row>;
};
export const StyledColHead = (props: any) => {
  return <Col className={container}>{props.children}</Col>;
};
export const Title = (props: {
  children: string | JSX.Element | JSX.Element[];
  id?: string;
}) => {
  return (
    <div id={props.id} className={title}>
      {props.children}
    </div>
  );
};
export const SubTitle = (props: {
  children: string | JSX.Element | JSX.Element[];
}) => {
  return <div className={subTitle}>{props.children}</div>;
};
export const HotelName = (props: {
  children: string | JSX.Element | JSX.Element[];
}) => {
  return (
    <div className={`mt-4 mb-5 ${hotelName}`} id="propName">
      {props.children}
    </div>
  );
};
export const GuestInfo = (props: {
  children: string | JSX.Element | JSX.Element[];
}) => {
  return <div className={guestInfo}>{props.children}</div>;
};

// export const CheckoutHeader = (props: any) => {
//     return (<StyledColHead>
//         <SubTitle>Reserve Your Room</SubTitle>
//         <Title id={"guest-details"}>Guest Details</Title>
//         <HotelName ><Link className={hotelTitleLink} to={props.hotel?.path?.alias}>{props.hotel.name}</Link></HotelName>
//     </StyledColHead>);
// };

export const TrackErrorMessage = (props: {
  message: string;
  field: string;
}) => {
  const trackMessage =
    props.message && typeof props.message !== undefined
      ? `${props.message} - ${props.field}`
      : "";
  return (
    <ErrorMessageAlert
      errorType={Constants.ERRORSTYPE.CHECKOUT}
      errorSubType={Constants.ERRORSSUBTYPE.CHECKOUT.GUESTDETAILS}
      message={trackMessage}
    >
      {props.message}
    </ErrorMessageAlert>
  );
};
