// extracted by mini-css-extract-plugin
export var alt = "CheckoutGuestDetails-module--alt--e57b7";
export var backtoProperty = "CheckoutGuestDetails-module--backtoProperty--b2743";
export var container = "CheckoutGuestDetails-module--container--84cfe";
export var desktopOnly = "CheckoutGuestDetails-module--desktopOnly--d9d55";
export var guestInfo = "CheckoutGuestDetails-module--guestInfo--c7b52";
export var hotelName = "CheckoutGuestDetails-module--hotelName--8b5f9";
export var main = "CheckoutGuestDetails-module--main--bcaf8";
export var mobileOnly = "CheckoutGuestDetails-module--mobileOnly--cf6c7";
export var nextButton = "CheckoutGuestDetails-module--nextButton--4af3f";
export var subTitle = "CheckoutGuestDetails-module--subTitle--0d5e7";
export var termsLinkPosition = "CheckoutGuestDetails-module--termsLinkPosition--e2bf6";
export var textLeft = "CheckoutGuestDetails-module--textLeft--1b3f0";
export var title = "CheckoutGuestDetails-module--title--9248f";
export var visuallyHidden = "CheckoutGuestDetails-module--visuallyHidden--7e8d8";