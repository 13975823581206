import React, { useState } from "react";
import { Container, ListGroupServices, ServiceOptions } from "./CheckoutAddOnsHelpers";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListGroup from "react-bootstrap/ListGroup";

const CheckoutAddOns = () => {
    const checkout = useSelector((state: any) => state.checkout);
    const checkoutRooms: Array<any> = checkout.Rooms ? Object.values(checkout.Rooms) : [];

    const [activeRoomIndex, setActiveRoomIndex] = useState(checkoutRooms[0] && checkoutRooms[0].id);
    const [loadingServices, setLoadingServices] = useState(true);
    const [servicesFound, setServicesFound] = useState(true);

    const handleServiceLoad = (servicesFound: boolean) => {
      setServicesFound(servicesFound);
      setLoadingServices(false);
    };

    return (<Container className={`${servicesFound ? 'd-block' : 'd-none'}`}>
                <Row className={`align-items-center ${servicesFound && 'mb-3'}`} noGutters>
                    <Col xs="auto">
                        <h5 className="h6 mb-3 heading">
                            <FontAwesomeIcon icon="plus-circle" className="me-2 icon"/>
                            Additional Options
                            {loadingServices && <Spinner size="sm" animation="border" className="ms-2"/>}
                        </h5>
                    </Col>
                </Row>
                {checkoutRooms.length > 1 ?
                <ListGroup
                    activeKey={activeRoomIndex}
                    defaultActiveKey={activeRoomIndex}
                    className={`p-0 ${servicesFound && 'mb-3'}`}
                    variant="flush"
                >
                    {checkoutRooms.map((room, key) => {
                    return (
                        <ListGroupServices key={room.id} 
                        room={room} 
                        roomNumber={key + 1}
                         expand={key == 0 || activeRoomIndex == room.id} 
                         onServiceLoad={handleServiceLoad}/>
                    );
                    })}
                </ListGroup>
                :
                <ServiceOptions room={checkoutRooms[0]} onServiceLoad={handleServiceLoad}/>
                }
            </Container>);
};

export default CheckoutAddOns;