import * as React from "react";
import { Row } from "react-bootstrap";
import { container, styledRow, tooltip, freeStay } from "./CheckoutTotal.module.scss";

export const Container = (props: any) => {
    return (<div className={`${container} ${props.className}`}>{props.children}</div>);
};
export const StyledRow = (props: any) => {
    return (<Row className={`${props.className} ${styledRow} g-0`}>{props.children}</Row>);
};
export const Tooltip = React.forwardRef((props: any, ref) => {
    return (<span className={tooltip} {...props} ref={ref}>{props.children}</span>);
});

export const FreeStay = () => {
    return <span className={freeStay}>FREE STAY</span>;
};

