import React, { useEffect, useState } from "react";
import { ICheckoutTotalProps, ServiceProps } from "./CheckoutTotalProps";
import { Container, StyledRow, Tooltip } from "./CheckoutTotalHelpers";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, parseSearchDate } from "../../../services/dates";
import { addCheckoutTotal } from "../../../redux/slices/Checkout/checkoutTotal";
import { Button, Col, Row } from "react-bootstrap";
import { priceFormatter } from "../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IRoom from "../../../@types/IRoom";
import moment from "moment-mini";
import { ITaxItem } from "../../../@types/ITaxItem";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { TP_REDEMPTION_RATE } from "../../../@types/Constants";
import { FreeStay } from "./CheckoutTotalHelpers";

const CheckoutTotal = (props: ICheckoutTotalProps) => {
  const dispatch = useDispatch();
  const checkoutObj = useSelector((state: any) => state.checkout);
  const checkInDate = parseSearchDate(checkoutObj.Start);
  const checkOutDate = parseSearchDate(checkoutObj.End);
  const [showPriceDetails, setShowPriceDetails] = useState(false);
  const [showTaxDetails, setShowTaxDetails] = useState(false);

  const formattedCheckIn = checkInDate ? formatDate(checkInDate, "MMM D") : "";
  const formattedCheckOut = checkOutDate
    ? formatDate(checkOutDate, "MMM D")
    : "";
  const stayDates = `${formattedCheckIn} - ${formattedCheckOut}`;
  const payment = checkoutObj.Payment || {};
  const applyBucks = !!payment.with_giftcards;
  const helloBucksAmount = payment.giftcard_amount;
  const detailsIcon = !showPriceDetails ? "angle-down" : "angle-up";
  const taxDetailsIcon = !showTaxDetails ? "angle-down" : "angle-up";
  let optionsTaxObject: any = {};
  let taxesAndFeesTotal = 0;
  let total = 0;
  let subTotal = 0;
  const nights =
    props.rooms[0].rate !== null &&
    props.rooms[0].rate.roomRateNightly.length > 1
      ? `${props.rooms[0].rate.roomRateNightly.length} Nights`
      : `1 Night`;
  let bucksApplied = 0;
  let remainingBucks = parseFloat(helloBucksAmount);
  const currency =
    props.rooms && props.rooms.length && props.rooms[0].rate
      ? props.rooms[0].rate?.currencyCode
      : "";

  const handleShowPriceDetails = () => {
    setShowPriceDetails(!showPriceDetails);
  };

  const isRedemptionRate =
    props.rooms &&
    props.rooms.length &&
    props.rooms[0].rate &&
    TP_REDEMPTION_RATE.includes(props.rooms[0].rate.RateCode);

  const taxItemsWise: Array<ITaxItem> = [];

  props.rooms.forEach((room: IRoom) => {
    const rate = room.rate;
    const services: ServiceProps[] = room.services;
    const servicesTax: any =
      Object.keys(optionsTaxObject).length === 0 ? {} : { ...optionsTaxObject };
    if (rate !== null) {
      if (applyBucks && remainingBucks > 0) {
        if (remainingBucks <= rate.subTotal) {
          bucksApplied += remainingBucks;
          remainingBucks = 0;
        } else {
          bucksApplied += parseFloat(rate.subTotal);
          remainingBucks = remainingBucks - parseFloat(rate.subTotal);
        }
      }
      let taxesAndFees = 0;
      rate.taxItems &&
        rate.taxItems.forEach((taxItem: ITaxItem) => {
          let taxItemAmount = parseFloat(taxItem.amount);
          if (taxItem.chargeFrequency == 1) {
            // per night
            const numOfNights =
              moment(taxItem.expireDate).diff(
                moment(taxItem.effectiveDate),
                "days"
              ) + 1;
            taxItemAmount = taxItemAmount * numOfNights;
          }
          if (taxItemAmount) {
            taxesAndFees += taxItemAmount;
          }

          const existingItemindex = taxItemsWise.findIndex(
            (item) => item.name === taxItem.name
          );
          if (existingItemindex > -1) {
            taxItemsWise[existingItemindex]["amount"] =
              taxItemsWise[existingItemindex]["amount"] + taxItemAmount;
          } else {
            taxItemsWise.push({ ...taxItem, amount: taxItemAmount });
          }
        });
      taxesAndFeesTotal += taxesAndFees;
      subTotal += parseFloat(rate.subTotal);
      // subTotal += 997.92;
    }
    if (services) {
      Object.values(services).forEach((service: ServiceProps) => {
        const serviceCount = parseInt(service.AvailedQuantity);
        taxesAndFeesTotal += parseFloat(service.Tax) * serviceCount;
        subTotal += parseFloat(service.Price) * serviceCount;
      });

      for (const [key, value] of Object.entries(services)) {
        if (servicesTax[key] === undefined) {
          servicesTax[key] = { Tax: value.Tax, Title: value.Title };
        } else {
          servicesTax[key] = {
            ...servicesTax[key],
            Tax: servicesTax[key]["Tax"] + value.Tax,
          };
        }
      }
    }
    total = subTotal + taxesAndFeesTotal - bucksApplied;
    optionsTaxObject = servicesTax;
  });
  bucksApplied = Math.ceil(bucksApplied);
  const getRoomServiceSubTotal = (room: IRoom) => {
    const services: ServiceProps[] = room.services;
    let serviceSubTotal = 0;
    if (services) {
      Object.values(services).forEach((service: ServiceProps) => {
        const serviceCount = parseInt(service.AvailedQuantity);
        serviceSubTotal += parseFloat(service.Price) * serviceCount;
      });
    }
    return serviceSubTotal;
  };
  useEffect(() => {
    dispatch(addCheckoutTotal(total));
  }, [props.rooms, total]);
  return (
    <Container className={props.className}>
      <StyledRow className="pt-3 border-top">
        {isRedemptionRate ? <FreeStay /> : null}
        <Col className="details px-0">
          <div className="heading">Total For Stay</div>
        </Col>
        <Col className="text-end total mb-2" lg="auto" xs="auto" id="cartTotal">
          {isRedemptionRate ? (
            <span>
              {checkoutObj?.requiredPoints
                ? `${checkoutObj?.requiredPoints.toLocaleString()} pts`
                : "FREE"}{" "}
            </span>
          ) : (
            priceFormatter(total, 2, currency)
          )}
        </Col>
      </StyledRow>
      <StyledRow className="flex-row-reverse">
        <Col xs="auto" className="text-end breakdown mb-2 mb-lg-0">
          {isRedemptionRate && (
            <div>Total Trip Cost: {priceFormatter(0, 2, currency)}</div>
          )}
          <div>
            {nights}{" "}
            {props.rooms.length > 1 ? `- ${props.rooms.length} Rooms` : ""}:{" "}
            {isRedemptionRate
              ? priceFormatter(0, 2, currency)
              : priceFormatter(subTotal, 2, currency)}
          </div>
          <div>
            Taxes &amp; Fees:{" "}
            {isRedemptionRate
              ? priceFormatter(0, 2, currency)
              : priceFormatter(taxesAndFeesTotal, 2, currency)}
          </div>
        </Col>
      </StyledRow>
      {showPriceDetails && (
        <StyledRow className={`pb-3 pb-lg-0`}>
          <Col className="px-0 d-flex align-items-end">
            <div className="breakdown mt-1 w-100">
              <div className="mb-n3 mb-lg-0">
                {props.rooms.map((room, idx) => {
                  if (room.rate) {
                    const servicePrice = getRoomServiceSubTotal(room);
                    return (
                      <div key={`room-price${idx}`}>
                        <div className="fw-bold mt-2">Room {idx + 1}</div>
                        <Row>
                          <Col>{nights}</Col>
                          <Col
                            style={{
                              display: "flex",
                              flexDirection: "row-reverse",
                            }}
                          >
                            {isRedemptionRate
                              ? priceFormatter(0, 2, currency)
                              : priceFormatter(room.rate.subTotal, 2, currency)}
                          </Col>
                        </Row>
                        {servicePrice > 0 &&
                          Object.keys(room.services).map(function (
                            key: string
                          ) {
                            return (
                              <Row key={key}>
                                <Col>
                                  {room.services[key]["Title"]}{" "}
                                  {room.services[key]["Description"] && (
                                    <Tippy
                                      content={
                                        room.services[key]["Description"]
                                      }
                                      theme="bootstrap"
                                    >
                                      <Tooltip>
                                        <FontAwesomeIcon
                                          icon={"info-circle"}
                                          className="icon"
                                        />
                                      </Tooltip>
                                    </Tippy>
                                  )}
                                </Col>
                                <Col
                                  style={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                  }}
                                >
                                  {priceFormatter(
                                    room.services[key]["Price"],
                                    2,
                                    currency
                                  )}
                                </Col>
                              </Row>
                            );
                          })}
                      </div>
                    );
                  }
                  return null;
                })}
                <div className="my-3">
                  <span className="fw-bold">Taxes &amp; Fees</span>
                  {taxItemsWise.map((tItem, idx) => {
                    if (tItem.amount) {
                      return (
                        <div key={`tax-item${idx}`}>
                          <Row>
                            <Col>
                              {tItem.name}{" "}
                              {tItem.description && (
                                <Tippy
                                  content={tItem.description}
                                  theme="bootstrap"
                                >
                                  <Tooltip>
                                    <FontAwesomeIcon
                                      icon={"info-circle"}
                                      className="icon"
                                    />
                                  </Tooltip>
                                </Tippy>
                              )}
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                              }}
                            >
                              {isRedemptionRate
                                ? priceFormatter(0, 2, currency)
                                : priceFormatter(
                                    parseFloat(tItem.amount),
                                    2,
                                    currency
                                  )}
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                    return null;
                  })}
                  {Object.keys(optionsTaxObject).length !== 0 &&
                    Object.entries(optionsTaxObject).map((item) => {
                      return (
                        <div key={`tax-item-${item[0]}`}>
                          <Row>
                            <Col>{item[1].Title} Tax</Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                              }}
                            >
                              {priceFormatter(item[1].Tax, 2, currency)}
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                </div>

                {applyBucks && (
                  <div className="my-3">
                    Hello Bucks: -{priceFormatter(bucksApplied)}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </StyledRow>
      )}
      <StyledRow className="border-bottom pb-2">
        <Col className="p-0">
          <Button
            variant="link"
            className="mt-2 p-0 text-uppercase price-btn"
            onClick={handleShowPriceDetails}
            id={`expand-price-details-button`}
          >
            {!showPriceDetails ? "Expand Price Details" : "HIDE Price Details"}
            <FontAwesomeIcon
              style={{ pointerEvents: "none" }}
              icon={detailsIcon}
              className="ms-1"
            />
          </Button>
        </Col>
      </StyledRow>
    </Container>
  );
};

export default CheckoutTotal;
