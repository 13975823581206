import { useEffect, useRef } from "react";

function useDebounce<T extends unknown[]>(
  callback: (...args: T) => void, // the function to be debounced
  delay: number, // the delay time in milliseconds
  dependencies: T // the dependencies that the debounced function depends on
) {
  const timerRef = useRef<NodeJS.Timeout>(); // holds the timeout reference

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current); // if there is a timer already set, clear it to avoid multiple timeouts
    }

    // set a new timeout with the given delay and dependencies
    timerRef.current = setTimeout(() => {
      callback(...dependencies);
    }, delay);

    // clean up function to clear the timeout if the component unmounts or the dependencies change
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current); // clear it to avoid memory leaks
      }
    };
  }, [...dependencies, delay]);
}
export default useDebounce;
