import * as React from "react";
import Row from "react-bootstrap/Row";
import { container, tooltip } from "./CheckoutOptionsItem.module.scss";

export const Container = (props: any) => {
    return (<Row className={`${container} bg-white  py-2 px-3 g-0`}>{props.children}</Row>);
};

export const Tooltip = React.forwardRef((props: any, ref) => {
    return (<span className={tooltip} {...props} ref={ref}>{props.children}</span>);
});



