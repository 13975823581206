import React, { useState } from "react";
import { IRoomTypeFilterProps } from "./RoomTypeFilterProps";
import { Container } from "./RoomTypeFilterHelpers";
import { Dropdown, Button, Modal, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMobileDetect from "../../../../hooks/useMobileDetect";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Constants } from "../../../../@types/Constants";

const RoomTypeFilter = (props: IRoomTypeFilterProps) => {
    const [roomType, setRoomType] = useState(props.filterRoomType || "");
    const [show, setShow] = useState(false);
    const isMobileOnly = useMobileDetect();
    const handleClose = () => setShow(false);
    const handleDropdownClick = (e: React.MouseEvent<HTMLElement>) => {
        if (e.currentTarget.id == "") {
            setRoomType("");
            props.setFilter("");
        } else {
            setRoomType(e.currentTarget.id);
            props.setFilter(e.currentTarget.id);
        }
    };
    const applyFilter = () => {
        if (roomType === "All") {
            props.setFilter("");
        } else {
            props.setFilter(roomType);
        }
        setShow(false);
    };
    return (
        <Container>
            {isMobileOnly ? (
                <div className="dropdown">
                    <Button onClick={() => setShow(true)}>
                    <span>{roomType || 'Filter'}</span>
                    <FontAwesomeIcon
                        icon="chevron-down"
                        className="drop-down-icon"
                    />
                </Button>
                </div>         
            ) : (
                <Dropdown navbar>
                    <Dropdown.Toggle variant="primary" id="dropdown-roomType">
                        <span>{roomType || 'Room Type'}</span>
                        <FontAwesomeIcon
                            icon="chevron-down"
                            className="drop-down-icon"
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={`ul`} className="p-1">
                        <Dropdown.Item
                            id=""
                            onClick={handleDropdownClick}
                            className={roomType === "" ? "active" : ""}
                        >
                            <span className="drop-down-title">All</span>
                            {roomType === "" ? (
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="custom-drop-down-icon"
                                />
                            ) : null}
                        </Dropdown.Item>
                        <Dropdown.Item
                            id={Constants.ROOM_AMINITY_ID}
                            onClick={handleDropdownClick}
                            className={
                                roomType === Constants.ROOM_AMINITY_ID
                                    ? "active"
                                    : ""
                            }
                        >
                            <span className="drop-down-title">Rooms</span>
                            {roomType === Constants.ROOM_AMINITY_ID ? (
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="custom-drop-down-icon"
                                />
                            ) : null}
                        </Dropdown.Item>
                        <Dropdown.Item
                            id={Constants.SUITE_AMINITY_ID}
                            onClick={handleDropdownClick}
                            className={
                                roomType === Constants.SUITE_AMINITY_ID
                                    ? "active"
                                    : ""
                            }
                        >
                            <span className="drop-down-title">Suites</span>
                            {roomType === Constants.SUITE_AMINITY_ID ? (
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="custom-drop-down-icon"
                                />
                            ) : null}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )}
            <Modal
                show={show}
                onHide={handleClose}
                className="custom-modal"
                dialogClassName="custom-dialog"
                aria-label="Room Filter"

            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Room Filter</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>
                        <b>Room type</b>
                    </h4>
                    <Row>
                        <Col xs={4}>
                            <Button
                                variant="primary"
                                id="All"
                                className={`${
                                    roomType === ""
                                        ? "custom-button active"
                                        : "custom-button"
                                }`}
                                onClick={() => setRoomType("")}
                            >
                                All
                            </Button>
                        </Col>
                        <Col xs={4}>
                            <Button
                                variant="primary"
                                id={Constants.ROOM_AMINITY_ID}
                                className={`${
                                    roomType === Constants.ROOM_AMINITY_ID
                                        ? "custom-button active"
                                        : "custom-button"
                                }`}
                                onClick={() =>
                                    setRoomType(Constants.ROOM_AMINITY_ID)
                                }
                            >
                                Rooms
                            </Button>
                        </Col>
                        <Col xs={4}>
                            <Button
                                variant="primary"
                                id={Constants.SUITE_AMINITY_ID}
                                className={`${
                                    roomType === Constants.SUITE_AMINITY_ID
                                        ? "custom-button active"
                                        : "custom-button"
                                }`}
                                onClick={() =>
                                    setRoomType(Constants.SUITE_AMINITY_ID)
                                }
                            >
                                Suites
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <div className="d-grid gap-2">
                            <Button
                                variant="primary"
                                className="room-filter-button"
                                onClick={applyFilter}
                            >
                                Apply Filter
                            </Button>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default RoomTypeFilter;
