import * as React from "react";
import { hiddenContainer } from "./Checkout.module.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import SubTitle from "../../components/global/SubTitle/SubTitle";
import CheckoutNavigation from "../../components/common/CheckoutNavigation/CheckoutNavigation";
import CheckoutSelectRoom from "../../components/common/CheckoutSelectRoom/CheckoutSelectRoom";
import { alertRow, alertIcon, alertMessage } from "./Checkout.module.scss";

export const HiddenContainer = (props: any) => {
  return (
    <div className={hiddenContainer}>
      <h1 className="visually-hidden">Checkout</h1>
      <div id="cartstack-params" className="visually-hidden" aria-hidden="true">
        {props.cartstackParams}
      </div>
      <div className="visually-hidden" id="propAddress" aria-hidden="true">
        {props.hotel
          ? `${props.hotel.address.address_line1},${props.hotel.address.locality},${props.hotel.address.administrative_area},${props.hotel.address.postal_code}`
          : null}
      </div>
    </div>
  );
};
export const CheckoutAlert = (props: {
  children: React.ReactChild;
  handleClearError(): void;
}) => {
  return (
    <Row className={`px-lg-5 mx-lg-5 ${alertRow}`}>
      <Col>
        <Alert
          variant="danger"
          className="text-center"
          dismissible
          onClose={props.handleClearError}
        >
          <Row>
            <Col className="col-1 d-flex justify-content-center">
              <span className={alertIcon}>
                <svg className="icon">
                  <use xlinkHref="/svg/error.svg#error"></use>
                </svg>
              </span>
            </Col>
            <Col>
              <span className={alertMessage}>{props.children}</span>
            </Col>
          </Row>
        </Alert>
      </Col>
    </Row>
  );
};
export const Accomendations = (props: {
  hotel: any;
  checkout: any;
  handleClearError(): void;
  currentRoomIndex: number;
  handleSetCurrentRoomIndex(event: any): void;
  handleOnRoomsLoad(roomsRates: any): void;
  step: any;
}) => {
  return (
    <React.Fragment>
      <SubTitle>Accommodations</SubTitle>
      <Row className="g-0">
        <Col>
          <h1 className="mb-2 mb-lg-4">
            {Object.values(props.checkout.Rooms).length > 1
              ? "Select Your Rooms"
              : "Select Your Room"}
          </h1>
        </Col>
        <CheckoutNavigation
          rooms={Object.values(props.checkout.Rooms)}
          currentRoomKey={props.currentRoomIndex}
          setCurrentRoomIndex={props.handleSetCurrentRoomIndex}
        />
        <CheckoutSelectRoom
          index={props.currentRoomIndex}
          setCurrentRoomIndex={props.handleSetCurrentRoomIndex}
          onRoomsLoad={props.handleOnRoomsLoad}
          checkoutStep={props.step}
          hotel={props.hotel}
          unlockBannerShow={props.unlockBannerShow}
        />
      </Row>
    </React.Fragment>
  );
};
