import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { container } from "./CheckoutAddOns.module.scss";
import { IListServicesGroup, IServiceOptions } from "./CheckoutAddOnsProps";
import Axios from "axios";
import { coreGet } from "../../../services/core";
import { buildFetchRatesUrl } from "../../../services/rates";
import { convertArrayToObject } from "../../../utils/helpers";
import { setCheckout } from "../../../redux/slices/Checkout/checkout";
import CheckoutOptionsItem from "../CheckoutOptionsItem/CheckoutOptionsItem";
import { guestServiceProvider } from "../../../services/ServiceProvider";

export const Container = (props: any) => {
  return (
    <div
      className={`${container} ${props.className} bg-light pt-4 pb-2 mb-5 px-2 px-lg-3 rounded`}
    >
      {props.children}
    </div>
  );
};

export const ListGroupServices = (props: IListServicesGroup) => {
  const [openRoomCard, setOpenRoomCard] = useState(props.expand);
  const [noServicesFound, setNoServicesFound] = useState(false);
  const handleClickRoomCard = () => {
    setOpenRoomCard(!openRoomCard);
  };
  const handleServiceLoaded = (servicesFound: boolean) => {
    if (!servicesFound) {
      setNoServicesFound(true);
    }
    props.onServiceLoad && props.onServiceLoad(servicesFound);
  };

  return !noServicesFound ? (
    <ListGroup.Item
      action
      eventKey={props.room.id}
      as={Card}
      className="p-0 bg-light"
    >
      <Card.Header
        className="d-flex flex-row border-top position-relative"
        onClick={handleClickRoomCard}
      >
        <div className="me-2 text-nowrap">
          <strong>Room {props.roomNumber}</strong>
        </div>
        <FontAwesomeIcon
          icon={`${openRoomCard ? "angle-up" : "angle-down"}`}
          className="icon"
        />
      </Card.Header>
      <Card.Body className={`p-0 my-0 mx-2`}>
        <ServiceOptions
          room={props.room}
          className={`p-0 ${!openRoomCard && "d-none"}`}
          onServiceLoad={handleServiceLoaded}
        />
      </Card.Body>
    </ListGroup.Item>
  ) : (
    <></>
  );
};
export const ServiceOptions = (props: IServiceOptions) => {
  const dispatch = useAppDispatch();
  const [services, setServices] = useState<Array<any> | null>(null);
  const [loadingServices, setLoadingServices] = useState(false);
  const checkout = useAppSelector((state: any) => state.checkout);
  const checkoutRooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
  const rateCode = props.room.rate && props.room.rate.RateCode;

  // Fetch pricing.
  useEffect(() => {
    const source = Axios.CancelToken.source();
    let didCancel = false;

    const fetchServices = async (apiUrl: string) => {
      let fetchedServices = null;

      const guestService = await guestServiceProvider();
      const hotelAvailablityResponse = await guestService.getHotelAvailability(
        props.room.adults,
        props.room.children,
        props.room.childrenAges,
        checkout.HotelCode,
        checkout.chainId,
        checkout.Start,
        checkout.End,
        "NonRoom",
        checkout.promotionCode,
        checkout.groupCode || rateCode,
        checkout.discount || "Retail"
      );
      // const data = await coreGet(apiUrl, source);
      const hotelData = hotelAvailablityResponse.hotelAvailablity?.queryHotel;

      if (hotelData !== null) {
        // fetchedServices = (Object as any).values((data))[0].Services;
        fetchedServices = hotelData.services.map((item) => ({
          Quantity: item.quantity.toString(),
          Title: item.name,
          Description: item.description,
          ServiceInventoryCode: item.code,
          ServicePricingType: item.pricingType,
          Price: {
            Rate: item.rate.toFixed(2),
            Taxes: item.taxes.toFixed(2),
            Total: item.total.toFixed(2),
          },
        }));
        // fetchedServices = [];
      }
      if (fetchedServices) {
        setServices(fetchedServices);
        props.onServiceLoad &&
          props.onServiceLoad(fetchedServices && fetchedServices.length > 0);
      }
    };
    // ASE - Availibilty Call
    (async () => {
      setLoadingServices(true);
      const apiUrl = buildFetchRatesUrl(
        checkout.Crs,
        checkout.HotelCode,
        checkout.Start,
        checkout.End,
        props.room.adults,
        props.room.children,
        checkout.promotionCode,
        checkout.groupCode,
        rateCode,
        props.room.childrenAges,
        null,
        checkout.discount
      );
      if (apiUrl && !services) {
        await fetchServices(apiUrl);
        if (!didCancel) {
          setLoadingServices(false);
        }
      }
    })();

    return () => {
      didCancel = true;
      source.cancel(`Request cancelled for fetch rooms services`);
    };
  }, [rateCode]);

  useEffect(() => {
    let didCancel = false;
    if (services) {
      let servicesUpdated = false;
      const updatedRooms: any = checkoutRooms;
      checkoutRooms.forEach(function (room: any, key: number) {
        let updatedRoomServices: any = props.room.services || {};
        !props.room.services &&
          services.forEach((service) => {
            servicesUpdated = true;
            if (Math.ceil(service.Price.Rate) === 0) {
              const _newService = {
                [service.ServiceInventoryCode]: {
                  Description: service.Description,
                  ServiceRPH: service.ServiceRPH,
                  ServiceInventoryCode: service.ServiceInventoryCode,
                  ServicePricingType: service.ServicePricingType,
                  Price: 0,
                  Tax: 0,
                  AvailedQuantity: 1,
                  Title: service?.Title,
                },
              };
              updatedRoomServices = { ...updatedRoomServices, ..._newService };
            }
          });
        updatedRooms[key] = { ...room, services: updatedRoomServices };
      });
      const newCheckout = {
        ...checkout,
        Rooms: convertArrayToObject(updatedRooms, "id"),
      };
      !didCancel && servicesUpdated && dispatch(setCheckout(newCheckout));
    }

    return () => {
      didCancel = true;
    };
  }, [services]);

  const uniqueServices =
    services &&
    Array.from(new Set(services.map((s) => s.ServiceInventoryCode))).map(
      (code) => services.find((s) => s.ServiceInventoryCode === code)
    );

  const options = uniqueServices?.map((service) => {
    if (service?.ServiceInventoryCode) {
      const inventoryCode = service.ServiceInventoryCode;
      const nights = props.room?.rate?.roomRateNightly?.length || 1;
      const occupancy = props.room.adults + props.room.children;

      return (
        <Col xs="12" className={`mb-2`} key={`col-${inventoryCode}`}>
          <CheckoutOptionsItem
            key={`${props.room.id}-${inventoryCode}`}
            room={props.room}
            service={service}
            nights={nights}
            occupancy={occupancy}
          />
        </Col>
      );
    }
  });

  return services && services.length ? (
    <Row className={`pb-2 ${props.className}`}>{options}</Row>
  ) : null;
};
