import React from "react";
import { IRoomSortProps } from "./RoomSortProps";
import { SortWrapper } from "./RoomSortHelpers";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RoomSort = (props: IRoomSortProps) => {
  const handleOrderToggle = (event: any) => {
    props.setSortOrderValue(event);
  };
  return (
    <SortWrapper>
      <Dropdown className="room-sort-dropdown">
        <Dropdown.Toggle variant="filter-dropdown" id="room-sort-dropdown">
          <span>{props.sortOrderValue}</span>
          <FontAwesomeIcon icon="chevron-down" />
        </Dropdown.Toggle>
        <Dropdown.Menu as={`ul`} id={"room-sort-down"}>
          <li>
            <Dropdown.Item
              eventKey={"Lowest Price"}
              onSelect={handleOrderToggle}
            >
              Lowest Price
            </Dropdown.Item>

            <Dropdown.Item
              eventKey={"Highest Price"}
              onSelect={handleOrderToggle}
            >
              Highest Price
            </Dropdown.Item>
          </li>
        </Dropdown.Menu>
      </Dropdown>
    </SortWrapper>
  );
};

export default RoomSort;
